import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "../locales/en/translation.json";
import translationNative from "../locales/native/translation.json";

//Config
const options = {
    resources: {
        en: { translation: translationEN },
        native: { translation: translationNative },
    },
    fallbackLng: "en", //Default language
    interpolation: {
        escapeValue: false,
    },
};

i18n.use(initReactI18next).init(options);

export default i18n;