import React  from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { motion as m } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { GeoApi } from '../Service/GeoApi';

import Header from "./Header";

import Home from "./Home";
import AboutMe from "./AboutMe";
import Projects from "./Projects";
import Contact from "./Contact";

import Project1 from "./Project1";
import Project2 from "./Project2";
import Project3 from "./Project3";
import Project4 from "./Project4";

import "./styles/sass/styles-sass"

const App = () => {
    const { i18n } = useTranslation();

    useEffect(() => {
        const hasVisited = localStorage.getItem("hasVisited");
    
        if (!hasVisited) {
            GeoApi().then((countryName) => {
            handleLanguageChange(countryName);
            localStorage.setItem("countryName", countryName);
          });
          localStorage.setItem('hasVisited', true);
        } else {
            const countryName = localStorage.getItem("countryName");
            handleLanguageChange(countryName);
        }
      }, []);

    const handleLanguageChange = (countryName) => {
        if (countryName === "Serbia") {
            i18n.changeLanguage("native");
        } else {
            i18n.changeLanguage("en");
        }
    };

    return (
        <div className="app-container">            
        <BrowserRouter>
            <Header />
            <m.div className="display-transition" 
                style={{ perspective: '500px' }}
                animate={{ rotateX: [90, -15, 0], opacity: [0, 0.5, 1] }}
                transition={{ duration: 1.2 }}
            >
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="/pocetna" element={<Home />} />

                    <Route path="/o-meni" element={<AboutMe />} />

                    <Route path="/projekti" element={<Projects />} />
                    <Route path="/projekat1-spring" element={<Project1 />} />
                    <Route path="/projekat2-spring" element={<Project2 />} />
                    <Route path="/projekat3-core" element={<Project3 />} />
                    <Route path="/projekat4-laravel" element={<Project4 />} />

                    <Route path="/kontakt" element={<Contact />} />
                </Routes>
            </m.div>          
            </BrowserRouter>
        </div>
    )
}

export default App;