import { motion as m } from "framer-motion";
import SpringProjectBooks from "./images/spring-books.jpg";
import { useTranslation } from "react-i18next";

const Project1 = () => {
    const { t } = useTranslation();

    return (
        <div className="projectdetail-container">
            <div className="container">

                <div className="row align-items-center justify-content-center">
                    <m.div className="col-sm-4 text-center" initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0}}>
                        <h4 className="projectdetail-title mb-0">{t("project.java-book.title")}</h4>
                    </m.div>
                </div>

                <div className="row align-items-center justify-content-center">
                    <m.div className="projectdetail col-sm-8 mt-2 pb-0" initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0}}>
                        <img className="projectdetail-image" src={SpringProjectBooks} alt="Slika projekta"></img>
                        <m.div className="col-sm-12 p-2 text-center" initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0.1}}>
                            <a href="https://github.com/bogdanmaric/spring-boot-restful-books-api"  rel="noreferrer" target="_blank" className="projectdetail-link">
                                {t("project.java-book.link")}
                            </a>
                        </m.div>
                    </m.div>
                </div>

                <div className="row align-items-center justify-content-center">
                    <m.div className="projectdetail-description col-sm-10 mt-2" initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0.1}}>
                        <p>{t("project.java-book.text")}</p>
                    </m.div>
                </div>

            </div>
        </div>
    );
}
export default Project1;