import { NavLink } from "react-router-dom";
import { motion as m } from "framer-motion";
import { useTranslation } from "react-i18next";

import SpringProjectBooks from "./images/spring-books.jpg";
import SpringProjectMovies from "./images/spring-movies.jpg";
import CoreProjectBooks from "./images/core-books.jpg";
import LaravelProjectStore from "./images/Laravel-store.jpg";

const Projects = () => {
    const { t } = useTranslation();

    return (
        <div className="project-container">
            <div className="container d-flex align-items-center justify-content-center h-100">
                <div className="row align-items-center justify-content-center">

                <NavLink to="/projekat1-spring" className="project col-sm-4" >
                    <m.div initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0}}>
                        <div className="col-sm-12 project-info text-center">
                            <p>{t("projects.java-book")}</p>
                            <img className="project-image" src={SpringProjectBooks} alt="Slika projekta"></img>
                            <p className="project-tech">Spring Boot | MySQL</p>
                        </div>
                    </m.div>
                </NavLink>

                <NavLink to="/projekat2-spring" className="project col-sm-4" >
                    <m.div initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0.1}}>
                        <div className="col-sm-12 project-info text-center">
                            <p>{t("projects.java-movie")}</p>
                            <img className="project-image" src={SpringProjectMovies} alt="Slika projekta"></img>
                            <p className="project-tech">Spring Boot | MongoDB</p>
                        </div>
                    </m.div>
                </NavLink>

                <NavLink to="/projekat3-core" className="project col-sm-4" >
                    <m.div initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0.2}}>
                        <div className="col-sm-12 project-info text-center">
                            <p>{t("projects.c#-book")}</p>
                            <img className="project-image" src={CoreProjectBooks} alt="Slika projekta"></img>
                            <p className="project-tech">ASP.NET Core | MySQL</p>
                        </div>
                    </m.div>
                </NavLink>

                <NavLink to="/projekat4-laravel" className="project col-sm-4" >
                    <m.div initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0.2}}>
                        <div className="col-sm-12 project-info text-center">
                            <p>{t("projects.laravel-store")}</p>
                            <img className="project-image" src={LaravelProjectStore} alt="Slika projekta"></img>
                            <p className="projects-tech">Laravel | MySQL</p>
                        </div>
                    </m.div>
                </NavLink>
                    
                </div>
            </div>
        </div>
    );
}

export default Projects;