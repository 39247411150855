//import profile from "./profile.png"
import { motion as m } from "framer-motion";
import { SocialIcon } from 'react-social-icons';
import { useTranslation } from "react-i18next";

import Footer from "./Footer";

const Contact = () => {
    const { t } = useTranslation();

    return (
        <m.div className="contact-container" initial={{opacity:0}} animate={{opacity:1}}>

            <div className="contact-text-container">
                <p>{t("contact.info")}</p>
                <br/>
                <div className="social-media">
                    <m.div className="linkedin" initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0}}>
                        <a href="https://linkedin.com/in/bogdan-marić-aa9311180"  rel="noreferrer" className="linkedin-link" target="_blank">
                            <SocialIcon className="linkedin-icon" network="linkedin" fgColor="white"></SocialIcon>
                            <span>{t("contact.linkedin")}</span>
                        </a>
                    </m.div>


                    <br/>

                    <m.div className="email" initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0.1}}>
                        <SocialIcon network="email" bgColor="lightblue" fgColor="black"></SocialIcon>
                        <span>{t("contact.email")}</span>
                    </m.div>

                    <br/>

                    <m.div className="github" initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0}}>
                        <a href="https://github.com/bogdanmaric" rel="noreferrer" className="linkedin-link" target="_blank">
                            <SocialIcon className="linkedin-icon" network="github" fgColor="white"></SocialIcon>
                            <span>{t("contact.github")}</span>
                        </a>
                    </m.div>
                </div>

                <br/>
                <div>
                    <p>{t("contact.respect")}</p>
                    <p>{t("contact.signature")}</p>
                </div>
            </div>
            <Footer />

        </m.div>
    )
}

/*
<div className="contact-container-FAQs">

                    <a className="email" href="/o-meni">
                        Email
                    </a>

                    <a className="linkedin" href="/o-meni">
                        LinkedIn
                    </a>

                    <a className="github" href="/o-meni">
                        GitHub
                    </a>

                </div>



            <div class="contact-faq-section">
                <h4>FAQs</h4>
                <dl>
                    <dt>Q: Da li imate iskustvo sa radom na realnim projektima?</dt>
                    <dd>A: Za sada još uvek nema iskustvo u radu sa realnim projektima, radio sam na nekoliko projekata sa fakulteta i ličnih u slobono vreme.
                        Možete pogledate detaljnije o ovim projektima u delu "Projekti".</dd>
                    
                    <dt>Q: Sa kojim programske jezicima i alatima ste najviše upoznati?</dt>
                    <dd>A: Najviše sam do sada koristio Javu, zatim PHP i C#. Na svakom projektu korisitm i HTML, CSS, JavaScript, MySQL, Git i GitHub.</dd>
                    
                    <dt>Q: Da li ste otvoreni za posao sa punim ili skraćenim radnim vremenom?</dt>
                    <dd>A: Trenutno mi je cilj da nađem posao sa punim radnim vremenom, ali sam svakako otvoren i za poslove sa skraćenim radnim vremenom.</dd>

                    <dt>Q: Da li ste otvoreni za praksu (plaćena/neplaćena)?</dt>
                    <dd>A: Svakako, otvorena sam i za plaćenu i neplaćenu praksu.</dd>
                </dl>
            </div>
*/

export default Contact;