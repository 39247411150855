import { motion as m } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import profile from "./images/profile.png";

const Profile = () => {
    const { t } = useTranslation();

    return (
        <m.div className="home-container" initial={{opacity:0}} animate={{opacity:1}}>

                <m.div initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0}} className="image-container">
                    <img  className="image-profile" src={profile} alt="profile"/>
                </m.div>

                <m.div initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0.1}} className="text-container-introduction">
                    <p className="text-greeting">{t("home.welcome")}</p>
                    <p className="text-role">{t("home.role1")}</p>
                    <p className="text-role">{t("home.role2")}</p>
                </m.div>

                <m.div initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0.2}} className="text-container-languanges">
                    <p className="text-languanges">*** Java - C# - MySQL ***</p>
                </m.div>

                <m.div initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0.3}} className="button-container">
                    <NavLink className="button-about-me" to="/o-meni">{t("home.button")}</NavLink>
                </m.div>

            </m.div>
    )
}

export default Profile;