import axios from 'axios';

export async function GeoApi() {
  try {
    const res = await axios.get('https://geolocation-db.com/json/');
    return res.data.country_name;
  } catch (error) {
    console.log(error);
    return null;
  }
}
