import { motion as m } from "framer-motion";
import { useTranslation } from "react-i18next";

const AboutMe = () => {
    const { t } = useTranslation();

    return (
        <div className="about-me-container">
            <div className="info-container">

                <div className="info-layout">

                    <m.div className="info" initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0}}>
                        <h3>{t("aboutme.education-title")}</h3>
                        <p>{t("aboutme.education-text")}</p>
                    </m.div>
                    
                    <m.div className="info" initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0.1}}>
                        <div>
                            <h3>{t("aboutme.skills-title")}</h3>
                            <p>{t("aboutme.skill.lang")}</p>
                            <p>{t("aboutme.skill.framework-library")}</p>
                            <p>{t("aboutme.skill.tool-technology")}</p>
                            <p>{t("aboutme.skill.database")}</p>
                            <p>{t("aboutme.skill.other")}</p>
                        </div>
                    </m.div>

                </div>

                <m.div className="info" initial={{opacity:0}} animate={{opacity:1}} transition={{delay: 0}}>
                    <h3>{t("aboutme.experience-title")}</h3>
                        <div className="info-experience">
                            <div className="experience">
                                <p>{t("aboutme.experience.asse.title")}</p>
                                <p>{t("aboutme.experience.asse.text")}</p>
                            </div>

                            <div className="experience">
                                <p>{t("aboutme.experience.levi.title")}</p>
                                <p>{t("aboutme.experience.levi.text")}</p>
                            </div>
                        </div>
                </m.div>
            </div>
        </div>
    );
}

export default AboutMe;