import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { motion as m } from "framer-motion";
import { useTranslation } from "react-i18next";

const Navigation = () => {
    const location = useLocation().pathname.substring(1).toLowerCase();
    const { t } = useTranslation();

    return (
        <nav>
            <NavLink to="/pocetna" className={location === "pocetna" || location === "" ? 'nav-link selected' : "nav-link"}>
                <m.div initial={{opacity:0, scale:0}} animate={{opacity:1, scale:1}} transition={{delay: 0.3}}>
                    <div className="nav-link-text">{t("nav.home")}</div>
                </m.div>
            </NavLink>

            <NavLink to="/o-meni" className={location === "o-meni" ? 'nav-link selected' : "nav-link"}>
                <m.div initial={{opacity:0, scale:0}} animate={{opacity:1, scale:1}} transition={{delay: 0.4}}>
                    <div className="nav-link-text">{t("nav.aboutme")}</div>
                </m.div>
            </NavLink>
            
            <NavLink to="/projekti" className={location === "projekti" ? 'nav-link selected' : "nav-link"}>
                <m.div initial={{opacity:0, scale:0}} animate={{opacity:1, scale:1}} transition={{delay: 0.5}}>
                    <div className="nav-link-text">{t("nav.projects")}</div>
                </m.div>
            </NavLink>
        
            
            <NavLink to="/kontakt"  className={location === "kontakt" ? 'nav-link selected' : "nav-link"}>
                <m.div initial={{opacity:0, scale:0}} animate={{opacity:1, scale:1}} transition={{delay: 0.6}}>
                    <div className="nav-link-text">{t("nav.contact")}</div>
                </m.div>
            </NavLink>
            
        </nav>
    )
}

export default Navigation;