import React from "react";

const Footer = () => {
    return (
    <div className="footer-container">
        <footer>
            <div className="footer-signature">
                <small>&copy; 2023 Bogdan Marić development. All rights reserved</small>
            </div>
        </footer>
    </div>
    )
}

export default Footer;