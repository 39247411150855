import React from "react";
import Navigation from "./Navigation";

//import { HeaderWrapper } from "./styles/styled-components/Header.styled";

const Header = () => {
    return (
        <div className="header-container">
            <header>
                <Navigation />
            </header>
        </div>
    )
}

export default Header;